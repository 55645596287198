<template>
    <div>
        <Card>
            <div slot="title">{{ title }}</div>
            <!-- <Card v-if="!isEdit" title="账号信息" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">账号昵称</div>
                <Input class="width_300" v-model.trim="formData.nickName" :maxlength="15" show-word-limit />
            </div>
            <div class="form_item">
                <div class="form_label must_input">账号</div>
                <Input class="width_300" v-model.trim="formData.userPhone" :maxlength="15" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">密码</div>
                <Input class="width_300" type="password" v-model.trim="formData.passWord" password></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">角色选择</div>
                <Select class="width_300" v-model="formData.groupId">
                    <Option v-for="info in roleList" :key="info.id" :value="info.id">{{ info.groupname }}</Option>
                </Select>
            </div>
        </Card> -->
            <div style="margin-bottom: 15px" v-if="isDetail == 'true'">
                <div style="font-size: 28px">{{ formData.name }}</div>
                <div>人员ID：{{ formData.id_card_no }}</div>
                <div v-if="isDetail == 'true' && isProxy == '1'" style="position: absolute; top: 67px; left: 112px">
                    <Tag color="primary" v-if="isDetail == 'true' && formData.status == 0" style="margin-left: 25px">已暂停</Tag>
                    <Tag color="success" v-if="isDetail == 'true' && formData.status == 1" style="margin-left: 25px">代理中</Tag>
                </div>
                <div v-if="isDetail == 'true' && isProxy == '2'" style="position: absolute; top: 67px; left: 112px">
                    <Tag color="primary" v-if="isDetail == 'true' && formData.process_status == 0" style="margin-left: 25px">待审核</Tag>
                    <Tag color="success" v-if="isDetail == 'true' && formData.process_status == 1" style="margin-left: 25px">已审核</Tag>
                    <Tag color="error" v-if="isDetail == 'true' && formData.process_status == 2" style="margin-left: 25px">已驳回</Tag>
                </div>
            </div>
            <Card title="基本信息" dis-hover v-if="isDetail == 'true'">
                <div style="width: 100%; background-color: rgb(246, 246, 246); height: 200px; padding: 40px">
                    <div style="display: flex; position: relative">
                        <div style="display: flex">
                            <div class="form_label" v-if="yyType == '省代理'">代理人姓名：</div>
                            <div class="form_label" v-if="yyType == '经销商'">经销商姓名：</div>
                            <div class="form_label" v-if="yyType == '小B端'">个人姓名：</div>
                            <div style="width: 200px">{{ formData.name }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 25%">
                            <div class="form_label">手机号码：</div>
                            <div style="width: 200px">{{ formData.phone }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 50%">
                            <div class="form_label">性别：</div>
                            <div style="width: 200px">{{ formData.sex }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 75%">
                            <div class="form_label" style="width: 75px">家庭住址：</div>
                            <div style="width: 200px">{{ formData.home_address }}</div>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 30px; position: relative">
                        <div style="display: flex">
                            <div class="form_label">证件号码：</div>
                            <div style="width: 200px">{{ formData.id_card_no }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 25%">
                            <div class="form_label">办公详细地址：</div>
                            <div style="width: 200px">{{ formData.office_address }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 50%">
                            <div class="form_label">注册日期：</div>
                            <div style="width: 200px">{{ formData.created_at }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 75%">
                            <div class="form_label" style="width: 75px">卡属银行：</div>
                            <div style="width: 200px">{{ getBankname(formData.bank_code) }}</div>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 30px; position: relative">
                        <div style="display: flex">
                            <div class="form_label">所属支行：</div>
                            <div style="width: 200px">{{ formData.bank_branch }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 25%">
                            <div class="form_label">银行卡号：</div>
                            <div style="width: 200px">{{ formData.bank_account }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 50%" v-if="yyType == '省代理'">
                            <div class="form_label">代理省份：</div>
                            <div style="width: 200px">{{ province }}</div>
                        </div>
                    </div>
                </div>
            </Card>
            <Card title="基本信息" dis-hover v-else>
                <div class="form_item" v-if="yyType == '小B端'">
                    <div class="form_label must_input">类型</div>
                    <RadioGroup v-model="formData.status1" type="button" button-style="solid">
                        <Radio label="1">个人</Radio>
                        <Radio label="0">小B端</Radio>
                    </RadioGroup>
                </div>
                <div class="form_item">
                    <div class="form_label must_input" v-if="yyType == '省代理'">代理人姓名</div>
                    <div class="form_label must_input" v-if="yyType == '经销商'">经销商姓名</div>
                    <div class="form_label must_input" v-if="yyType == '小B端'">个人姓名</div>
                    <div v-if="isDetail == 'true'">{{ formData.name }}</div>
                    <Input class="width_300" v-else v-model.trim="formData.name" />
                    <div v-if="isDetail == 'true' && isProxy == '1'">
                        <Tag color="primary" v-if="isDetail == 'true' && formData.status == 0" style="margin-left: 25px">已暂停</Tag>
                        <Tag color="success" v-if="isDetail == 'true' && formData.status == 1" style="margin-left: 25px">代理中</Tag>
                    </div>
                    <div v-if="isDetail == 'true' && isProxy == '2'">
                        <Tag color="primary" v-if="isDetail == 'true' && formData.process_status == 0" style="margin-left: 25px">待审核</Tag>
                        <Tag color="success" v-if="isDetail == 'true' && formData.process_status == 1" style="margin-left: 25px">已审核</Tag>
                        <Tag color="error" v-if="isDetail == 'true' && formData.process_status == 2" style="margin-left: 25px">已驳回</Tag>
                    </div>
                </div>
                <!-- <div class="form_item" v-if="yyType == '省代理'">
                    <div class="form_label must_input">代理省份</div>
                    <Select class="width_300" v-model="formData.province_code" :disabled="isEdit == 'true'">
                        <Option v-for="info in province_list" :key="info.code" :value="info.code">{{ info.name }}</Option>
                    </Select>
                    <div>{{ info.name}}</div>
                </div> -->
                <div class="form_item">
                    <div class="form_label must_input">办公所在位置</div>
                    <div v-if="isDetail == 'true'">{{ address[0] }}/{{ address[1] }}/{{ address[2] }}</div>
                    <city v-else v-model="formData.areaarr"></city>
                </div>
                <div class="form_item">
                    <div class="form_label must_input">办公详细地址</div>
                    <div v-if="isDetail == 'true'">{{ formData.office_address }}</div>
                    <Input class="width_300" v-else v-model.trim="formData.office_address" />
                </div>
                <div class="form_item">
                    <div class="form_label must_input">分佣比例</div>
                    <div v-if="isDetail == 'true'">{{ formData.rate_using }}</div>
                    <InputNumber v-else class="width_300" :max="100" v-model="formData.rate_using"  /> <span style="margin-left: 10px;">%</span>
                </div>
                <div class="form_item" v-if="yyType == '省代理'">
                    <div class="form_label must_input">人员身份</div>
                    <Select class="width_300" v-model="ryStatus">
                        <Option v-for="info in ryList" :key="info.code" :value="info.status">{{ info.name }}</Option>
                    </Select>
                    <!-- <div>{{ info.name}}</div> -->
                </div>
                <div class="form_item">
                    <div class="form_label must_input">手机号码</div>
                    <div v-if="isDetail == 'true'">{{ formData.phone }}</div>
                    <Input class="width_300" @on-blur="isPhoneNumberValid" v-else v-model="formData.phone" />
                </div>
                <!-- <div class="form_item">
                <div class="form_label must_input">登录密码</div>
                <Input class="width_300" type="password" v-model="formData.password" password></Input>
            </div> -->
                <div class="form_item">
                    <div class="form_label must_input">性别</div>
                    <div v-if="isDetail == 'true'">
                        <span v-if="formData.sex == 1">男</span>
                        <span v-if="formData.sex == 2">女</span>
                    </div>
                    <Select class="width_300" v-model="formData.sex" v-else>
                        <Option value="1">男</Option>
                        <Option value="2">女</Option>
                    </Select>
                </div>
                <div class="form_item">
                    <div class="form_label">家庭住址</div>
                    <div v-if="isDetail == 'true'">{{ formData.home_address }}</div>
                    <Input v-else class="width_300" v-model="formData.home_address" />
                </div>
                <div class="form_item">
                    <div class="form_label must_input">证件号码</div>
                    <div v-if="isDetail == 'true'">{{ formData.id_card_no }}</div>
                    <Input class="width_300" @on-blur="checkIDNumber" v-else v-model="formData.id_card_no" />
                </div>
                <div class="form_item">
                    <div class="form_label must_input">选择银行</div>
                    <Select class="width_300" v-model="formData.bank_code">
                        <Option v-for="(info, index) in bankList" :key="index" :value="info.code">{{ info.name }}</Option>
                    </Select>
                    <!-- <div>{{ info.name}}</div> -->
                </div>
                <div class="form_item">
                    <div class="form_label must_input">银行支行</div>
                    <div v-if="isDetail == 'true'">{{ formData.bank_branch }}</div>
                    <Input v-else class="width_300" v-model="formData.bank_branch" />
                </div>
                <div class="form_item">
                    <div class="form_label must_input">银行卡号码</div>

                    <div v-if="isDetail == 'true'">{{ formData.bank_acount }}</div>

                    <!-- <input
                        v-else
                        class="width_300"
                        @blur="checkBankNumber"
                        type="text"
                        autocomplete="off"
                        style="
                            display: inline-block;
                            width: 100%;
                            height: 32px;
                            line-height: 1.5;
                            padding: 4px 7px;
                            font-size: 14px;
                            border: 1px solid #dcdee2;
                            border-radius: 4px;
                            color: #515a6e;
                            background-color: #fff;
                            background-image: none;
                            position: relative;
                            cursor: text;
                            transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
                        "
                        v-model="formData.bank_account"
                    /> -->

                    <Input v-else class="width_300" @on-blur="checkBankNumber" autocomplete="off" v-model="formData.bank_account" />
                </div>
                <div class="form_item">
                    <div class="form_label align_self_start must_input">身份证人像页照片</div>
                    <div>
                        <div class="relative upload_panel">
                            <img :src="formData.id_card_front" v-if="isDetail == 'true'" style="width: 300px; height: 150px; object-fit: contain" />
                            <UploadImage v-else :width="300" :height="150" v-model="formData.id_card_front" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                        </div>
                    </div>
                </div>
                <div class="form_item" style="margin-top: 80px">
                    <div class="form_label align_self_start must_input">身份证国徽页照片</div>
                    <div>
                        <div class="relative upload_panel">
                            <img :src="formData.id_card_back" v-if="isDetail == 'true'" style="width: 300px; height: 150px; object-fit: contain" />
                            <UploadImage v-else :width="300" :height="150" v-model="formData.id_card_back" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                        </div>
                    </div>
                </div>
                <div class="form_item" style="margin-top: 80px">
                    <div class="form_label align_self_start must_input">个人寸照</div>
                    <div>
                        <div class="relative upload_panel">
                            <img :src="formData.person_inch" v-if="isDetail == 'true'" style="width: 300px; height: 150px; object-fit: contain" />
                            <UploadImage v-else :width="300" :height="150" v-model="formData.person_inch" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                        </div>
                    </div>
                </div>
                <div class="form_item" style="margin-top: 80px">
                    <div class="form_label align_self_start">营业执照</div>
                    <div>
                        <div class="relative upload_panel">
                            <img :src="formData.license" v-if="isDetail == 'true'" style="width: 300px; height: 150px; object-fit: contain" />
                            <UploadImage v-else :width="300" :height="150" v-model="formData.license" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                        </div>
                    </div>
                </div>
                <div class="form_item" style="position: absolute; z-index: -999">
                    <div class="form_label must_input">登录账号</div>
                    <Input class="width_300" v-model.trim="yc" :maxlength="15" show-word-limit></Input>
                </div>
            </Card>
            <Card title="代理数据" dis-hover v-if="isDetail == 'true'">
                <div style="width: 100%; background-color: rgb(246, 246, 246); height: 150px; padding: 40px">
                    <div style="display: flex; position: relative">
                        <div style="display: flex">
                            <div class="form_label">经销商数：</div>
                            <div style="width: 200px">{{ formData.dealer_count }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 25%">
                            <div class="form_label">个人/小B端：</div>
                            <div style="width: 200px">{{ formData.person_count }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 50%">
                            <div class="form_label">分佣比例：</div>
                            <div style="width: 200px">{{ formData.rate_using }}</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 75%">
                            <div class="form_label" style="width: 75px">角色身份：</div>
                            <div style="width: 200px">{{ yyType }}</div>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 30px; position: relative">
                        <div style="display: flex">
                            <div class="form_label">剩余佣金：</div>
                            <div style="width: 30px">{{ syNum }}</div>
                            <div style="color: blue; cursor: pointer" @click="toDetail">查看明细</div>
                            <div style="color: blue; margin-left: 10px; cursor: pointer" @click="Export">下载报表</div>
                        </div>
                        <div style="display: flex; position: absolute; left: 25%">
                            <div class="form_label">累计订单：</div>
                            <div style="width: 200px">{{ formData.order_count }}</div>
                        </div>
                    </div>
                </div>
            </Card>
            <Card title="个人信息" dis-hover v-if="isDetail == 'true'">
                <div class="form_item">
                    <div class="form_label align_self_start">身份证人像页照片</div>
                    <div>
                        <div class="relative upload_panel">
                            <img :src="formData.id_card_front" v-if="isDetail == 'true'" style="width: 300px; height: 150px; object-fit: contain" />
                            <UploadImage v-else :width="300" :height="150" v-model="formData.id_card_front" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                        </div>
                    </div>
                </div>
                <div class="form_item" style="margin-top: 80px">
                    <div class="form_label align_self_start">身份证国徽页照片</div>
                    <div>
                        <div class="relative upload_panel">
                            <img :src="formData.id_card_back" v-if="isDetail == 'true'" style="width: 300px; height: 150px; object-fit: contain" />
                            <UploadImage v-else :width="300" :height="150" v-model="formData.id_card_back" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                        </div>
                    </div>
                </div>
                <div class="form_item" style="margin-top: 80px">
                    <div class="form_label align_self_start">个人寸照</div>
                    <div>
                        <div class="relative upload_panel">
                            <img :src="formData.person_inch" v-if="isDetail == 'true'" style="width: 300px; height: 150px; object-fit: contain" />
                            <UploadImage v-else :width="300" :height="150" v-model="formData.person_inch" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                        </div>
                    </div>
                </div>
                <div class="form_item" style="margin-top: 80px">
                    <div class="form_label align_self_start">营业执照</div>
                    <div>
                        <div class="relative upload_panel">
                            <img :src="formData.license" v-if="isDetail == 'true'" style="width: 300px; height: 150px; object-fit: contain" />
                            <UploadImage v-else :width="300" :height="150" v-model="formData.license" @success="onSuccessUploadImg" @remove="onRemoveImg"></UploadImage>
                        </div>
                    </div>
                </div>
            </Card>
            <div class="mt_50 flex align_center justify_center form_item">
                <i-button type="primary" ghost @click="onCloseEdit">返回</i-button>
                <i-button class="ml_50" type="success" v-if="isDetail != 'true'" :loading="ajaxLoading" @click="onConfirm">保存</i-button>
            </div>
            <mySpin :loading="ajaxLoading"></mySpin>
            <Modal v-model="showList" title="佣金明细" width="1000" height="1000">
                <DatePicker v-model="daterange" :transfer="true" @on-change="handleDateChange" split-panels confirm :editable="false" type="daterange" placeholder="自定义日期"></DatePicker>
                <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onSearch" style="margin-left: 25px">搜索</i-button>
                <i-button type="primary" ghost style="margin-left: 5px" @click="reset">重置</i-button>

                <Table :key="tableKey" stripe class="table mt_10" :columns="dataColumn" :data="yjDataList">
                    <template slot-scope="{ row }" slot="process">
                        <span :class="'now_state_' + row.process" v-if="row.process_status == 1">已审核</span>
                        <span :class="'now_state_' + row.process" v-if="row.process_status == 2">已驳回</span>
                        <span :class="'now_state_' + row.process" v-if="row.process_status == 0">待审核</span>
                    </template>
                    <template slot-scope="{ row }" slot="type">
                        <span v-if="row.type == 1">省代理</span>
                    </template>
                    <template slot-scope="{ row }" slot="manager">
                        <div class="table_manager flex flex_wrap align_center">
                            <!-- <Button type="success" size="small" ghost @click="onView(row)">详情</Button> -->
                            <Button type="primary" size="small" ghost @click="onPause(row)">通过</Button>
                            <Button type="primary" size="small" ghost @click="onbh(row)">驳回</Button>
                        </div>
                    </template>
                </Table>
                <Page :total="totalNum" :page-size="query.limit" @on-change="changeTotal" />
                <mySpin :loading="ajaxLoading"></mySpin>
            </Modal>
        </Card>
    </div>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqGroupList, addAgent, updateAgent, readAgent, totalFunds, getincExpLog } from '@/lib/request/auth';
import city from '@/components/city';
import { reqMerchantCreateAccount, reqMerchantSaveAccount, yjmxExport } from '@/lib/request/auth2';
import { getCityTitles } from '@/lib/city.data';
import UploadImage from '@/components/uploadImage';

export default {
    name: 'serviceProviderAdd',
    mixins: [pageMixins],
    components: {
        city,
        UploadImage,
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            yc: 0,
            totalNum: 0,
            daterange: '',
            query: {
                page: 1,
                limit: 10,
            },
            searchs: [
                // { id: hm.createId(), label: '身份ID', placeholder: '请输入身份ID', type: 'input', bind: 'id_card_no' },
                { id: hm.createId(), label: '提现人', placeholder: '请输入提现人', type: 'input', bind: 'au_name' },
                { id: hm.createId(), label: '人员ID', placeholder: '请输入人员ID', type: 'input', bind: 'au_id_card_no' },
                { id: hm.createId(), label: '提现单号', placeholder: '请输入人员ID', type: 'input', bind: 'bank_no' },
                {
                    id: hm.createId(),
                    label: '主体',
                    placeholder: '选择主体',
                    type: 'select',
                    bind: 'process_status',
                    list: [
                        { id: '4', title: '小B端' },
                        { id: '3', title: '个人' },
                        { id: '2', title: '经销商' },
                        { id: '1', title: '省代理' },
                    ],
                },
            ],
            dataList: [],
            tableKey: 'tableKey',
            dataColumn: [
                { key: 'type_text', title: '内容' },
                { key: 'created_at', title: '时间' },
                { key: 'amount', title: '流动金额' },
                { key: 'balance', title: '剩余佣金' },
            ],
            showList: false,
            yyType: '',
            bankList: [],
            BankObj: {
                工商银行: 'ICBC',
                农业银行: 'ABC',
                中国银行: 'BCM',
                建设银行: 'CCB',
                交通银行: 'BOC',
                平安银行: 'PSBC',
                兴业银行: 'CIB',
                华夏银行: 'HXB',
                民生银行: 'CMSB',
                光大银行: 'CEB',
                北京银行: 'BJB',
                上海银行: 'SHB',
                深圳银行: 'SRCB',
                重庆银行: 'CWB',
                广东发展银行: 'GDB',
                上海农商银行: 'SHRCB',
                浙江农商银行: 'ZJTLCB',
                江苏农商银行: 'JSB',
                四川农商银行: 'SCRCB',
                天津农商银行: 'TJRCB',
                重庆农商银行: 'WRCB',
                浙江省农信社: 'ZJTLCB',
                上海省农信社: 'SHRCB',
                江苏省农信社: 'JSB',
                浙江农信: 'ZJTLCB',
                上海农信: 'SHRCB',
                江苏农信: 'JSB',
                浙江省农商: 'ZJTLCB',
                上海省农商: 'SHRCB',
                江苏省农商: 'JSB',
            },
            ryStatus: 1,
            ryList: [
                {
                    status: 1,
                    name: '社会合作伙伴',
                },
                {
                    status: 2,
                    name: '公司员工',
                },
            ],
            isProxy: '',
            title: null,
            formData: {
                phone: '',
                rate: '',
                areaarr: '',
                name: '',
                id: '',
                status: '1',
                rate_using:''
            },
            address_code: [],
            address_note: null,
            roleList: [],
            address: [],
            isEdit: false,
            isDetail: false,
            province: '',
            arrPic: [],
            pid: '',
            syNum: 0,
            yjDataList: [],
            startdate: '',
            enddate: '',
            province_list: [
                {
                    name:'琪朗总部',
                    code:'1'
                },
                {
                    name: '北京市',
                    code: '110000',
                },
                {
                    name: '天津市',
                    code: '120000',
                },
                {
                    name: '河北省',
                    code: '130000',
                },
                {
                    name: '山西省',
                    code: '140000',
                },
                {
                    name: '内蒙古自治区',
                    code: '150000',
                },
                {
                    name: '辽宁省',
                    code: '210000',
                },
                {
                    name: '吉林省',
                    code: '220000',
                },
                {
                    name: '黑龙江省',
                    code: '230000',
                },
                {
                    name: '上海市',
                    code: '310000',
                },
                {
                    name: '江苏省',
                    code: '320000',
                },
                {
                    name: '浙江省',
                    code: '330000',
                },
                {
                    name: '安徽省',
                    code: '340000',
                },
                {
                    name: '福建省',
                    code: '350000',
                },
                {
                    name: '江西省',
                    code: '360000',
                },
                {
                    name: '山东省',
                    code: '370000',
                },
                {
                    name: '河南省',
                    code: '410000',
                },
                {
                    name: '湖北省',
                    code: '420000',
                },
                {
                    name: '湖南省',
                    code: '430000',
                },
                {
                    name: '广东省',
                    code: '440000',
                },
                {
                    name: '广西壮族自治区',
                    code: '450000',
                },
                {
                    name: '海南省',
                    code: '460000',
                },
                {
                    name: '重庆市',
                    code: '500000',
                },
                {
                    name: '四川省',
                    code: '510000',
                },
                {
                    name: '贵州省',
                    code: '520000',
                },
                {
                    name: '云南省',
                    code: '530000',
                },
                {
                    name: '西藏自治区',
                    code: '540000',
                },
                {
                    name: '陕西省',
                    code: '610000',
                },
                {
                    name: '甘肃省',
                    code: '620000',
                },
                {
                    name: '青海省',
                    code: '630000',
                },
                {
                    name: '宁夏回族自治区',
                    code: '640000',
                },
                {
                    name: '新疆维吾尔自治区',
                    code: '650000',
                },
                {
                    name: '台湾省',
                    code: '710000',
                },
                {
                    name: '香港特别行政区',
                    code: '810000',
                },
                {
                    name: '澳门特别行政区',
                    code: '820000',
                },
            ],
        };
    },
    created() {
        // this.$nextTick(()=>{
        //     setTimeout(() => {
        //     const aotufills = document.querySelectorAll('input:-webkit-autofill');
        //     console.log(aotufills,'666')
        //     aotufills.forEach(item => {
        //         item.style.color = '';
        //         item.value = '';
        //     });
        // }, 200);
        // })
    },
    mounted() {
        for (var key in this.BankObj) {
            var obj = {};
            obj.name = key;
            obj.code = this.BankObj[key];
            this.bankList.push(obj);
        }
        console.log(this.bankList, ' this.bankList');
        this.routeParamsInjectFormData(['address_code', 'address_note']);
        this.isEdit = this.$route.query.isEdit;
        this.formData.id = this.$route.query.id;
        this.isDetail = this.$route.query.isDetail;
        this.isProxy = this.$route.query.isProxy;
        console.log(this.$route.query.yyType, 'sss');
        this.yyType = this.$route.query.yyType;
        console.log(this.$route.query.isEdit, 'this.$route.query.isEdit');
        this.pid = this.$route.query.pid;
        if (this.pid == '0') {
            this.title = this.isEdit == 'true' ? '编辑省代理信息' : '新增省代理信息';
        } else if (this.yyType == '经销商') {
            this.title = this.isEdit == 'true' ? '编辑经销商信息' : '新增经销商信息';
        } else {
            this.title = this.isEdit == 'true' ? '编辑小B端信息' : '新增小B端信息';
        }
        if (this.isDetail == 'true') {
            this.title = '详情';
        }

        this.getRoleList();
        this.getDetail();
        if (this.isDetail == 'true') {
            this.gettotalFunds();
            this.getincExpLogList();
        }

        console.log(this.getBankname('SHRCB'), '???');
    },
    watch: {
        //
    },
    methods: {
        Export() {
            let params = window.structuredClone(this.query);
            params.export = 1;
            yjmxExport(params);
        },
        changeTotal(page) {
            this.query.page = page;
            this.getincExpLogList();
            console.log(page);
        },
        handleDateChange(date) {
            this.startdate = date[0];
            this.enddate = date[1];
        },
        reset() {
            this.query.start = undefined;
            this.query.enddate = undefined;
            this.daterange = undefined;
            this.getincExpLogList();
        },
        onSearch() {
            this.query.start = this.startdate;
            this.query.enddate = this.enddate;
            this.getincExpLogList();
        },
        getincExpLogList() {
            this.query.agent_id = this.formData.id;
            getincExpLog(this.query).then(res => {
                this.yjDataList = res.data.list;
                this.totalNum = res.data.total;
            });
        },
        toDetail() {
            this.showList = true;
        },
        gettotalFunds() {
            totalFunds({ id: this.formData.id }).then(res => {
                this.syNum = res.data.total_kickback - (res.data.withdrawing + res.data.withdrawn);
            });
        },
        //获取银行名称
        getBankname(bankcode) {
            var bankname = null;
            this.bankList.forEach(element => {
                if (element.code == bankcode) {
                    bankname = element.name;
                }
            });
            return bankname;
        },
        //验证银行卡号
        checkBankNumber() {
            const regex = /^\d{16,19}$/;
            if (!regex.test(this.formData.bank_account)) {
                this.fadeWarning('请输入正确的银行卡号码');
            }
        },
        //验证身份证号
        checkIDNumber() {
            const regex = /^[1-9]\d{16}(\d|X|x)$/;
            if (!regex.test(this.formData.id_card_no)) {
                this.fadeWarning('请输入正确的身份证号码');
            }
        },
        //验证手机号
        isPhoneNumberValid() {
            const regex = /^1\d{10}$/;
            if (!regex.test(this.formData.phone)) {
                this.fadeWarning('请输入正确的手机号码');
            }
        },
        //单个图片上传成功
        onSuccessUploadImg() {
            if (this.arrPic.length < this.maxLength && hm.isNotEmptyString(this.arrPic.slice(-1)[0])) {
                this.arrPic.push('');
            } else if (this.arrPic.length <= 0) {
                this.arrPic.push('');
            }
        },
        //删除某张图片
        onRemoveImg() {
            this.arrPic = this.arrPic.filter(src => !!src);
            this.onSuccessUploadImg();
            this.arrPic.push(0);
            this.arrPic.pop();
        },
        getRoleList() {
            this.ajaxLoading = true;
            reqGroupList({
                groupId: 6,
            })
                .then(res => {
                    let list = res?.data?.list || [];
                    this.roleList = list;
                })
                .finally(res => {
                    this.ajaxLoading = false;
                });
        },
        //获取详情
        getDetail() {
            if (this.isEdit == 'true') {
                this.showAjaxLoading();
                readAgent({ id: this.formData.id })
                    .then(res => {
                        this.formData = res.data;
                        let arr = JSON.parse(this.formData.area);
                        this.formData.areaarr = arr[0];
                        this.address = arr[1];
                        this.province = getCityTitles([this.formData.province_code])[0];
                        console.log(this.address, 'address');
                        this.dataInjectFormData(res);
                        // this.arrPic = this.formData.pic.split(this.picSeq);
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
        onConfirm() {
            // this.formData.area = this.address_code;
            console.log(this.address_code, 'address_code');

            this.isEdit == 'true' ? this.onConfirmEdit() : this.onConfirmSave();
        },
        onConfirmSave() {
            console.log(this.formData, 'this.formData');
            let address_code = this.formData.areaarr;
            console.log(getCityTitles(address_code), 'address_title');
            this.showAjaxLoading();
            if (this.yyType == '省代理') {
                if (this.ryStatus == 1) {
                    this.formData.type = 1;
                } else {
                    this.formData.type = 5;
                }
            } else if (this.yyType == '经销商') {
                this.formData.type = 2;
            } else if (this.yyType == '小B端') {
                if (this.formData.status1 == 1) {
                    this.formData.type = 4;
                } else {
                    this.formData.type = 3;
                }
            }
            if (this.yyType == '省代理') {
                this.formData.pid = 0;
            } else {
                this.formData.pid = this.pid;
            }
            let addressObj = [];
            addressObj.push(this.formData.areaarr);
            addressObj.push(getCityTitles(address_code));
            this.formData.area = JSON.stringify(addressObj);
            console.log(this.formData.area, 'this.formData.area');
            let params = window.structuredClone(this.formData);
            let { name, areaarr, phone, rate, sex, id_card_no, bank_code, bank_branch, bank_account,rate_using } = params || {};
            let vs = [
                { value: name, tip: '请输入姓名' },
                { value: rate_using, tip: '请输入分佣比例' },
                { value: phone, tip: '请输入手机号码' },
                // { value: password, tip: '请输入登录密码' },
                { value: sex, tip: '请选择性别' },
                { value: id_card_no, tip: '请输入证件号码' },
                { value: bank_code, tip: '请选择银行' },
                { value: bank_branch, tip: '请输入银行支行' },
                { value: bank_account, tip: '请输入银行号码' },
            ];

            // let vs = [];
            console.log(rate_using,'rate_using')
            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    console.log(address_code, 'address_code');
                    let address_title = getCityTitles(address_code);
                    console.log(address_title, 'address_title');
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    addAgent(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        onConfirmEdit() {
            console.log(this.formData, 'this.formData');
            let address_code = this.formData.areaarr;
            console.log(getCityTitles(address_code), 'address_title');
            this.showAjaxLoading();
            if (this.yyType == '省代理') {
                if (this.ryStatus == 1) {
                    this.formData.type = 1;
                } else {
                    this.formData.type = 5;
                }
            } else if (this.yyType == '经销商') {
                this.formData.type = 2;
            } else if (this.yyType == '小B端') {
                if (this.formData.status1 == 1) {
                    this.formData.type = 4;
                } else {
                    this.formData.type = 3;
                }
            }

            if (this.yyType == '省代理') {
                this.formData.pid = 0;
            } else {
                this.formData.pid = this.pid;
            }
            let addressObj = [];
            addressObj.push(this.formData.areaarr);
            addressObj.push(getCityTitles(address_code));
            this.formData.area = JSON.stringify(addressObj);
            console.log(this.formData.area, 'this.formData.area');
            let params = window.structuredClone(this.formData);
            // let { name, userName, userTel } = params || {};
            // let vs = [
            //     { value: name, tip: '请输入商务商名称' },
            //     { value: userName, tip: '请输入联系人' },
            //     { value: userTel, tip: '请输入手机号' },
            // ];
            let { name, areaarr, phone, password,rate_using, sex, id_card_no, bank_code, bank_branch, bank_account } = params || {};
            let vs = [
                { value: name, tip: '请输入姓名' },
                { value: rate_using, tip: '请输入分佣比例' },
                { value: phone, tip: '请输入手机号码' },
                { value: sex, tip: '请选择性别' },
                { value: id_card_no, tip: '请输入证件号码' },
                { value: bank_code, tip: '请选择银行' },
                { value: bank_branch, tip: '请输入银行支行' },
                { value: bank_account, tip: '请输入银行号码' },
            ];
            // let vs = [];
            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    let address_title = getCityTitles(address_code);
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    updateAgent(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped>
input:-webkit-autofill {
    -webkit-text-fill-color: transparent !important;
}
</style>
